import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { MapPin, Phone, Mail } from 'lucide-react';
import { toast } from 'react-hot-toast';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const partnerLogos = [
  {
    name: "Capredena",
    logo: "../Capredena.png"
  },
  {
    name: "Dipreca",
    logo: "../diprecalogo.jpg"
  }
];

export const Contact: React.FC = () => {
  const form = useRef<HTMLFormElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isSubmitting || !form.current) return;

    setIsSubmitting(true);

    try {
      await emailjs.sendForm(
        import.meta.env.VITE_EMAILJS_SERVICE_ID,
        import.meta.env.VITE_EMAILJS_TEMPLATE_ID,
        form.current,
        import.meta.env.VITE_EMAILJS_PUBLIC_KEY
      );

      toast.success('¡Mensaje enviado correctamente!');
      form.current.reset();
    } catch (error) {
      console.error('Error al enviar el mensaje:', error);
      toast.error('Error al enviar el mensaje. Por favor, intente nuevamente.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section id="contact" className="py-16 bg-[#f5f1e8]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-center text-[#2c4a3c] mb-12">Contáctanos</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div className="space-y-6">
            <div className="flex items-start space-x-4">
              <MapPin className="w-6 h-6 text-[#5c8d76] mt-1" />
              <div>
                <h3 className="text-xl font-semibold text-[#2c4a3c]">Ubicación</h3>
                <p className="text-gray-600">Las Violetas 2357, Providencia</p>
                <div className="mt-4 h-64 rounded-lg overflow-hidden">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.543005665752!2d-70.6167!3d-33.4367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf40d45d4fb7%3A0x8d0b0f0b0f0b0f0b!2sLas%20Violetas%202357%2C%20Providencia%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses!2scl!4v1620000000000!5m2!1ses!2scl"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <Phone className="w-6 h-6 text-[#5c8d76] mt-1" />
              <div>
                <h3 className="text-xl font-semibold text-[#2c4a3c]">Teléfono</h3>
                <p className="text-gray-600">+562 25679832</p>
                <p className="text-gray-600">+569 44006020</p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <Mail className="w-6 h-6 text-[#5c8d76] mt-1" />
              <div>
                <h3 className="text-xl font-semibold text-[#2c4a3c]">Email</h3>
                <p className="text-gray-600">residencia.stmargarita@gmail.com</p>
              </div>
            </div>
          </div>
          <form className="space-y-4" ref={form} onSubmit={sendEmail}>
            <input
              type="text"
              placeholder="Nombre"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#5c8d76]"
              name="from_name"
              required
            />
            <input
              type="email"
              placeholder="Email"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#5c8d76]"
              name="reply_to"
              required
            />
            <textarea
              placeholder="Mensaje"
              rows={4}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#5c8d76]"
              name="message"
              required
            ></textarea>
            <button
              type="submit"
              className="w-full bg-[#5c8d76] text-white px-6 py-3 rounded-lg hover:bg-[#2c4a3c] transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Enviando...' : 'Enviar Mensaje'}
            </button>
          </form>
        </div>

        <div className="mt-16">
          <h3 className="text-2xl font-bold text-center text-[#2c4a3c] mb-8">Nuestros Socios</h3>
          <div className="flex justify-center items-center gap-12">
            {partnerLogos.map((partner, index) => (
              <div key={index} className="w-40 h-20">
                <img
                  src={partner.logo}
                  alt={`${partner.name} logo`}
                  className="w-full h-full object-contain"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}