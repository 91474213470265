import React from 'react';
import { Heart, Shield, Clock } from 'lucide-react';

export function About() {
  return (
    <section className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-center text-[#2c4a3c] mb-12">Sobre Nosotros</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="text-center">
            <div className="flex justify-center mb-4">
              <Heart className="w-12 h-12 text-[#5c8d76]" />
            </div>
            <h3 className="text-xl font-semibold text-[#2c4a3c] mb-2">Compromiso</h3>
            <p className="text-gray-600">Dedicados a brindar el <strong>mejor cuidado y atención</strong> a nuestros ancianos con amor y dedicación en <strong>Providencia</strong>.</p>
          </div>
          <div className="text-center">
            <div className="flex justify-center mb-4">
              <Shield className="w-12 h-12 text-[#5c8d76]" />
            </div>
            <h3 className="text-xl font-semibold text-[#2c4a3c] mb-2">Experiencia</h3>
            <p className="text-gray-600">Fundado en 1964, somos el <strong>asilo para ancianos y adultos mayores</strong> con más experiencia y los más altos estándares de cuidado.</p>
          </div>
          <div className="text-center">
            <div className="flex justify-center mb-4">
              <Clock className="w-12 h-12 text-[#5c8d76]" />
            </div>
            <h3 className="text-xl font-semibold text-[#2c4a3c] mb-2">Atención 24/7</h3>
            <p className="text-gray-600">Servicio y cuidado profesional las 24 horas del día, los 7 días de la semana.</p>
          </div>
        </div>
        <div className="mt-12 text-center">
          <p className="text-gray-600 max-w-3xl mx-auto">
            En Residencia Santa Margarita, nos dedicamos a proporcionar un ambiente acogedor y seguro donde nuestros ancianos puedan disfrutar de una vida plena y confortable. Nuestro equipo de profesionales altamente capacitados está comprometido con el bienestar y la felicidad de cada uno de nuestros residentes. <strong>Resolución exenta n°49126</strong>.
          </p>
        </div>
      </div>
    </section>
  );
}