import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOHelmetProps {
  title: string;
  description: string;
  path: string;
}

export function SEOHelmet({ title, description, path }: SEOHelmetProps) {
  const baseUrl = 'https://residenciasantamargarita.cl';
  const fullUrl = `${baseUrl}${path}`;
  const defaultImage = '../logo.jpg';

  return (
    <Helmet>
      {/* Básico */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content="residencia adultos mayores, cuidado adulto mayor, hogar de ancianos, residencia geriátrica, cuidados post operatorios, hemiplejias, arteriosclerosis, atención médica, providencia, santiago" />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${baseUrl}${defaultImage}`} />
      <meta property="og:site_name" content="Residencia Santa Margarita" />
      <meta property="og:locale" content="es_CL" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={fullUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${baseUrl}${defaultImage}`} />

      {/* Geo Tags */}
      <meta name="geo.region" content="CL-RM" />
      <meta name="geo.placename" content="Providencia, Santiago" />
      <meta name="geo.position" content="-33.4367;-70.6167" />
      <meta name="ICBM" content="-33.4367, -70.6167" />

      {/* Adicional */}
      <meta name="author" content="Residencia Santa Margarita" />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={fullUrl} />
    </Helmet>
  );
}