import React from 'react';
import { Link } from 'react-scroll';

export function Hero() {
  return (
    <div id="home" className="relative h-[600px] overflow-hidden">
      <div 
        className="absolute inset-0 bg-cover bg-center transform scale-105 animate-subtle-zoom"
        style={{
          backgroundImage: "url('https://images.unsplash.com/photo-1576091160550-2173dba999ef?auto=format&fit=crop&q=80')",
        }}
      >
        <div className="absolute inset-0 bg-black/40"></div>
      </div>
      <div className="relative h-full flex items-center justify-center text-center">
        <div className="max-w-3xl px-4">
          <h1 className="text-5xl font-bold text-white mb-6">
            Bienvenidos a Residencia Santa Margarita - <strong>Asilo</strong> para <strong>Ancianos</strong> en <strong>Providencia</strong>
          </h1>
          <p className="text-xl text-white mb-8">
            Cuidado especializado y atención personalizada para nuestros <strong>adultos</strong> <strong>mayores</strong>. Expertos en cuidados post operatorios, hemiplejias y arteriosclerosis.
          </p>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={-64}
            duration={300}
            className="inline-block bg-[#5c8d76] text-white px-8 py-3 rounded-lg hover:bg-[#2c4a3c] transition duration-300 cursor-pointer"
          >
            Contáctanos Ahora
          </Link>
        </div>
      </div>
    </div>
  );
}