import React from 'react';
import { Stethoscope, Heart, Activity, Brain, Cctv } from 'lucide-react';

const services = [
  {
    icon: Heart,
    title: "Cuidados Post Operatorios",
    description: "Atención especializada y seguimiento profesional para una óptima recuperación post quirúrgica"
  },
  {
    icon: Activity,
    title: "Hemiplejias",
    description: "Cuidado experto y rehabilitación para pacientes con parálisis de un lado del cuerpo. Contamos con kinesiólogo y fisioterapeuta"
  },
  {
    icon: Brain,
    title: "Arteriosclerosis",
    description: "Tratamiento y seguimiento especializado para pacientes con endurecimiento arterial"
  },
  {
    icon: Stethoscope,
    title: "Atención Médica 24/7",
    description: "Equipo médico y de enfermería disponible las 24 horas para cualquier emergencia"
  },
  {
    icon: Cctv,
    title: "Seguridad",
    description: "Contamos con televigilancia las 24 horas del día."
  }
];

export function Services() {
  return (
    <section id="services" className="py-16 bg-[#f5f1e8]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-center text-[#2c4a3c] mb-12">Nuestros Servicios</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {services.map((service, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
              <service.icon className="w-12 h-12 text-[#5c8d76] mb-4" />
              <h3 className="text-xl font-semibold text-[#2c4a3c] mb-2">{service.title}</h3>
              <p className="text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}