import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { HelmetProvider } from 'react-helmet-async';
import { ScrollToTop } from './components/ScrollToTop';
import { Providers } from './components/Providers';
import Home from './pages/Home';
import logo from '../public/logo.jpg';
import Professionals from './pages/Professionals';

function App() {
  return (
    <Providers>
      <HelmetProvider>
        <Router>
          <ScrollToTop />
          <Suspense fallback={<div>Cargando...</div>}>
            <div className="min-h-screen">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/profesionales" element={<Professionals />} />
              </Routes>
              <FloatingWhatsApp
                phoneNumber="+56944006020"
                accountName="Residencia Santa Margarita"
                avatar={logo}
                statusMessage="Normalmente responde en 1 hora"
                chatMessage="¡Hola! 👋 ¿Cómo podemos ayudarte?"
                placeholder="Escribe un mensaje..."
              />
            </div>
          </Suspense>
        </Router>
      </HelmetProvider>
    </Providers>
  );
}

export default App;