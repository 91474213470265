import { useState } from 'react';
import { useLocation } from 'react-router-dom'; // Para obtener la ruta actual
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import logo from '../../public/logo.jpg';

export function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); // Ruta actual

  const isOnProfessionalsPage = location.pathname === '/profesionales';

  const menuItems = isOnProfessionalsPage
    ? [
        { to: "/", label: "Inicio", isExternal: true },
        { to: "/profesionales", label: "Profesionales", isExternal: false },
        { to: "/", label: "Servicios", isExternal: true },
        { to: "/", label: "Galería", isExternal: true },
        { to: "/", label: "Contacto", isExternal: true },
      ]
    : [
        { to: "home", label: "Inicio", isExternal: false },
        { to: "/profesionales", label: "Profesionales", isExternal: true },
        { to: "services", label: "Servicios", isExternal: false },
        { to: "gallery", label: "Galería", isExternal: false },
        { to: "contact", label: "Contacto", isExternal: false },
      ];

  return (
    <nav className="fixed top-0 left-0 right-0 bg-[#f5f1e8] shadow-md z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <a className="flex items-center" href='/'>
            <img
              src={logo}
              alt="Residencia Santa Margarita Logo"
              className="w-16 h-16 rounded"
            />
            <span className="ml-2 text-2xl font-semibold text-[#2c4a3c]">Residencia Santa Margarita</span>
          </a>
          
          {/* Desktop Menu */}
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
              {menuItems.map((item) =>
                item.isExternal ? (
                  <Link
                    key={item.to}
                    to={item.to}
                    className="text-[#2c4a3c] hover:text-[#5c8d76] px-3 py-2 cursor-pointer"
                  >
                    {item.label}
                  </Link>
                ) : (
                  <ScrollLink
                    key={item.to}
                    to={item.to}
                    spy={true}
                    smooth={true}
                    offset={-64}
                    duration={300}
                    className="text-[#2c4a3c] hover:text-[#5c8d76] px-3 py-2 cursor-pointer"
                  >
                    {item.label}
                  </ScrollLink>
                )
              )}
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-[#2c4a3c] hover:text-[#5c8d76]"
            >
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div className={`md:hidden ${isOpen ? 'block' : 'hidden'}`}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          {menuItems.map((item) =>
            item.isExternal ? (
              <Link
                key={item.to}
                to={item.to}
                className="block text-[#2c4a3c] hover:text-[#5c8d76] px-3 py-2 cursor-pointer"
                onClick={() => setIsOpen(false)}
              >
                {item.label}
              </Link>
            ) : (
              <ScrollLink
                key={item.to}
                to={item.to}
                spy={true}
                smooth={true}
                offset={-64}
                duration={300}
                className="block text-[#2c4a3c] hover:text-[#5c8d76] px-3 py-2 cursor-pointer"
                onClick={() => setIsOpen(false)}
              >
                {item.label}
              </ScrollLink>
            )
          )}
        </div>
      </div>
    </nav>
  );
}
