import React from 'react';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { SEOHelmet } from '../components/SEOHelmet';
import { Users } from 'lucide-react';

const professionals = [
  {
    name: "Guillermo Zambrano",
    role: "Médico cirujano",
    image: "../persona.jpg",
    description: "Experto en medicina geriátrica y cuidados paliativos con especialización en cirugia",
    specialties: []
  }
];

export default function Professionals() {
  return (
    <>
      <SEOHelmet
        title="Nuestros Profesionales | Serene Haven - Equipo Médico Especializado"
        description="Conoce a nuestro equipo de profesionales altamente calificados en Serene Haven. Médicos, enfermeros y especialistas dedicados al cuidado integral de adultos mayores."
        path="/profesionales"
      />
      <Navbar />
      <div className="pt-16 bg-[#f5f1e8]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="text-center mb-12">
            <div className="flex justify-center mb-4">
              <Users className="w-12 h-12 text-[#5c8d76]" />
            </div>
            <h1 className="text-4xl font-bold text-[#2c4a3c] mb-4">
              Nuestro Equipo Profesional
            </h1>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Contamos con un equipo multidisciplinario de profesionales altamente capacitados y comprometidos con el bienestar de nuestros residentes.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {professionals.map((professional, index) => (
              <div key={index} className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition duration-300">
                <div className="aspect-square">
                  <img
                    src={professional.image}
                    alt={professional.name}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-[#2c4a3c]">{professional.name}</h3>
                  <p className="text-[#5c8d76] font-medium mb-3">{professional.role}</p>
                  <p className="text-gray-600 mb-4">{professional.description}</p>
                  <div className="flex flex-wrap gap-2">
                    {professional.specialties.map((specialty, idx) => (
                      <span 
                        key={idx}
                        className="bg-[#f5f1e8] text-[#2c4a3c] text-sm px-3 py-1 rounded-full"
                      >
                        {specialty}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}