import React from 'react';
import logo from '../../public/logo.jpg';

export function Footer() {
  return (
    <footer className="bg-[#2c4a3c] text-white py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center mb-4 md:mb-0">
            <img
              src={logo}
              alt="Residencia Santa Margarita Logo"
              className="h-8 w-8 rounded-full"
            />
            <span className="ml-2 text-xl font-semibold"><strong>Residencia Santa Margarita</strong></span>
          </div>
          <div className="text-center md:text-right">
            <p>&copy; {new Date().getFullYear()} Residencia Santa Margarita. Todos los derechos reservados.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}