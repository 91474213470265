import React from 'react';
import { Navbar } from '../components/Navbar';
import { Hero } from '../components/Hero';
import { About } from '../components/About';
import { Services } from '../components/Services';
import { Gallery } from '../components/Gallery';
import { Contact } from '../components/Contact';
import { Footer } from '../components/Footer';
import { SEOHelmet } from '../components/SEOHelmet';

export default function Home() {
  return (
    <>
      <SEOHelmet
        title="Residencia Santa Margarita"
        description="Residencia Santa Margarita centro especializado en el cuidado y atención integral de adultos mayores con servicios personalizados y atención médica 24/7. Ofrecemos un ambiente acogedor y seguro para nuestros residentes."
        path="/"
      />
      <Navbar />
      <Hero />
      <About />
      <Services />
      <Gallery />
      <Contact />
      <Footer />
    </>
  );
}